<template>
  <div>发布时光</div>
</template>

<script>
export default {
  name: "Publish"
}
</script>

<style scoped>

</style>